import {initSelects} from "../../core/js/Select.ts";
import {initChampACompleter, initChampAModifier} from "../../core/js/FormHelper.ts";
import {initActions} from "./Actions.ts";
import {initGlobalSearch} from "./Search.ts";
import {$, $$, getParentElementByClassName} from "../../core/js/Dom.ts";
import {fadeIn} from "../../core/js/Animate.ts";
import {initHistoriqueList} from "../../core/js/List.ts";
import * as Sentry from "@sentry/browser"
import {createErrorNotification, createInfoNotification, createNotifications, createSuccessNotification, createWarningNotification} from "./Notifications.ts";
import {date_picker_init} from "../../core/js/DatePicker.ts";
import {initTooltips} from "../../core/js/Tooltip.ts";
import {registerModalListener} from "../../core/js/Modal.ts";


document.addEventListener('DOMContentLoaded', () => {
    // les initialisation globale sont a mettre ici (les tooltip, les select, les datepicker, etc...)
    date_picker_init();
    Sentry.init(window.Openscop.sentry);
    window.Sentry = {
        ...Sentry,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.breadcrumbsIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.feedbackIntegration(),
            Sentry.captureConsoleIntegration(),
            Sentry.extraErrorDataIntegration(),
            Sentry.debugIntegration(),
            Sentry.sessionTimingIntegration(),
        ],
    }
    initTooltips()
    initSelects()
    initChampACompleter()
    initChampAModifier()
    initGlobalSearch()
    exposeNotifications()
    initCkeditorEvent()
    registerModalListener()

    if (window.Openscop.url_get_action_bloc) initActions()
    if (!nav_obselete()) {
        fadeIn($<HTMLElement>('#nav_obselete'))
    }

    $$('.modifier').forEach((element) => getParentElementByClassName(element, 'fr-grid-row')?.classList.add('champ-a-modifier', 'champ'))

    if (document.getElementById('historique-list')) {
        initHistoriqueList()
    }
})

function nav_obselete() {
    let ua = navigator.userAgent
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    if (is_ie) {
        return false
    }
    let ok = true
    if (ua.search('Firefox/') !== -1) {
        ok = Number(ua.split('Firefox/')[1]) >= 60
    } else if (ua.search('Chrome/') !== -1) {
        ok = Number(ua.split('Chrome/')[1].split('.')[0]) >= 60
    }
    return ok
}

function exposeNotifications() {
    window.createSuccessNotification = createSuccessNotification
    window.createErrorNotification = createErrorNotification
    window.createInfoNotification = createInfoNotification
    window.createWarningNotification = createWarningNotification
    window.createNotifications = createNotifications
}

function initCkeditorEvent() {
    document.body.addEventListener('ckeditor:updated', (event: any) => {
        const editor = event.detail
        let notesParent = getParentElementByClassName(editor.sourceElement, "form_ajout_note")
        if (!notesParent) {
            notesParent = getParentElementByClassName(editor.sourceElement, 'fr-modal__content')
        }
        if (notesParent) {
            let button = $("button.btnsubmit_note", notesParent!)
            if (!button) {
                button = $('button.btn_submit_dropzone_note_modif', notesParent!)
            }
            const data = editor.getData()
            let emptyChild = 0
            // on peut parcourir les éléments identifier par ckeditor pour verifier s'ils sont vides ou non
            const childs = editor.data.parse(data)._children._nodes
            childs.forEach((child: any) => {
                if (child.isEmpty) emptyChild++
            })
            if (emptyChild !== childs.length) {
                button?.removeAttribute('disabled')
            } else {
                button?.setAttribute('disabled', 'true')
            }
        }
    })
}