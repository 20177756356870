import {$} from "../../core/js/Dom.ts";
import {fadeIn, fadeOut} from "../../core/js/Animate.ts";

document.addEventListener('DOMContentLoaded', () => {
    const scrollToTop = $<HTMLDivElement>('#scroll-to-top')
    if (scrollToTop) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                if(scrollToTop.classList.contains('d-none')) fadeIn(scrollToTop)
            } else {
                if(!scrollToTop.classList.contains('d-none')) fadeOut(scrollToTop)
            }
        }, {passive: true})
    }
})