import {$, hide} from "../../core/js/Dom.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url === 'contact') {
        handleContactForm();
    }
})

function handleContactForm() {
    const input_codePostal = $<HTMLInputElement>('#id_code');
    const select_departement = $<HTMLInputElement>('#id_departement');

    input_codePostal?.addEventListener('input', TestCodePostalEtDepartement);
    select_departement?.addEventListener('change', TestCodePostalEtDepartement);

    if (window.Openscop.is_log) {
        hide($<HTMLElement>('#id_nom')?.parentElement)
        hide($<HTMLElement>('#id_prenom')?.parentElement)
    }
}

function TestCodePostalEtDepartement() {
    const input_codePostal = $<HTMLInputElement>('#id_code');
    const select_departement = $<HTMLInputElement>('#id_departement');
    const button_submit = $<HTMLInputElement>('#submit-id-send');
    const departement_pk = select_departement?.value;
    const dep = select_departement?.querySelector<HTMLOptionElement>(`option[value="${departement_pk}"]`)!.innerText.split(' - ')[0].trim()
    let postalLength = 2;

    if(dep && input_codePostal) {
        if (dep > "970") {
            postalLength = 3;
        }
        const code = input_codePostal.value.substring(0, postalLength);

        if ((code === dep) && (input_codePostal.value.length === 5)) {
            button_submit?.removeAttribute('disabled');
        } else {
            button_submit?.setAttribute('disabled', 'disabled');
        }
    }
}