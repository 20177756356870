import {$, $$} from "../../core/js/Dom.ts";
import {$get} from "../../core/js/Ajax.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url === "compteur_absence_instruction_mairie") {
        $$('select').forEach(select => {
            select.addEventListener('change', handleDataChange)
        })
    }
})


function handleDataChange() {
    const form = $<HTMLFormElement>("#search-form")
    if (form) {
        const data = new FormData(form)
        $get(window.Openscop.url_search_absence_instruction_mairie, {
            annee: data.get('annee') ?? "",
            instance: data.get('instance') ?? "",
        }).then((reponse) => {
            const message_envoie_count = $("#message_envoie_count")
            const message_pas_agent_count = $("#message_pas_agent_count")
            const message_pas_agent_actif_count = $("#message_pas_agent_actif_count")
            if(message_envoie_count && message_pas_agent_count && message_pas_agent_actif_count) {
                message_envoie_count.innerText = reponse.body['message_envoie_count']
                message_pas_agent_count.innerText = reponse.body['message_pas_agent_count']
                message_pas_agent_actif_count.innerText = reponse.body['message_pas_agent_actif_count']
            }
        })
    }
}