import {$} from "../../core/js/Dom.ts";
import {fadeIn, fadeOut} from "../../core/js/Animate.ts";
import {debounce} from "lodash-es";
import {InitChampAdress} from "../../core/js/InitChampAdress.ts";

let initalValueSet = false

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url === 'inscription_organisateur') {
        initListeners()
        InitChampAdress('id_adresse_s')
    }
})

const initListeners = () => {
    const checkbox = $<HTMLInputElement>('#id_is_responsable_juridique_structure') as HTMLInputElement;
    const divToHide = $<HTMLDivElement>('#qualite_responsable_container');
    const responsable_juridique_qualite = $<HTMLInputElement>('#id_responsable_juridique_qualite_s')
    const responsable_juridique_telephone = $<HTMLInputElement>('#id_responsable_juridique_telephone')

    function cacherDivSiCochee() {
        if (checkbox.checked) {
            fadeIn(divToHide)
            responsable_juridique_qualite?.setAttribute('required', 'required')
            responsable_juridique_telephone?.setAttribute('required', 'required')
        } else {
            fadeOut(divToHide)
            responsable_juridique_qualite?.removeAttribute('required')
            responsable_juridique_telephone?.removeAttribute('required')
        }
    }

    // Écouteur d'événement pour la checkbox
    checkbox.addEventListener('change', cacherDivSiCochee);
    cacherDivSiCochee()


    // utilisation de l'api navigateur pour remonter les erreurs de forms, mettre un message vide permet de ne plus afficher le message si le champs a été modifier sinon le message reste toujours actif apres sont initialisation
    const isOrganisateurField = $<HTMLInputElement>('#is_organisateur')
    isOrganisateurField?.addEventListener('invalid', () => isOrganisateurField?.setCustomValidity(`Ce formulaire d'inscription est réservé aux déclarants. Si cela n'est pas votre cas, veuillez vous rapprocher des services compétents.`))
    isOrganisateurField?.addEventListener('input', () => isOrganisateurField?.setCustomValidity(``))
    const PeutRecevoirEmail = $<HTMLInputElement>('#peut_recevoir_mail')
    PeutRecevoirEmail?.addEventListener('invalid', () => PeutRecevoirEmail?.setCustomValidity(`La bonne réception des emails est indispensable pour utiliser la plateforme.`))
    PeutRecevoirEmail?.addEventListener('input', () => PeutRecevoirEmail?.setCustomValidity(``))
}