// function qui va construire le bandeau résumé d'un dossier.
import {$get} from "../../core/js/Ajax.ts";
import {$} from "../../core/js/Dom.ts";
import {initTooltips} from "../../core/js/Tooltip.ts";
import {fadeIn, fadeOut} from "../../core/js/Animate.ts";

function Bandeau() {
    if (window.Openscop.is_log) {
        const url = `${window.Openscop.url_bandeau}`
        $get(url).then((data) => {
            $('#dossier-resume')!.innerHTML = data.body;
            initTooltips($('#dossier-resume'));
            // si django nous renvoi une erreur (comme un 403 ca rpàas co) on arrete le rechargement du compteur

            initAnimations()
        }).catch(() => {
            window.Openscop.is_log = "False"
        })

    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (window.Openscop.url_bandeau) {
        Bandeau();
    }
})

function initAnimations() {
    const btn = $('#resume-infos-btn')

    btn?.addEventListener('click', toggleInformationsPanel)
}

function toggleInformationsPanel() {
    const infos = $('#resume-infos')
    const icon = $('#resume-infos-btn-detail')
    if (infos && icon) {
        if(infos.classList.contains('d-none')) {
            icon.innerText = '-'
            fadeIn(infos)
        } else {
            fadeOut(infos)
            icon.innerText = '+'
        }
    }
}