import {$get} from "../../core/js/Ajax.ts";
import {$, $$} from "../../core/js/Dom.ts";
import {switchTab} from "../../core/js/SwitchTab.ts";
import {showModalConfirmationCdsr} from "../../structure/js/CDSR.ts";
import {closeActionsMenu} from "./Actions.ts";

document.addEventListener('DOMContentLoaded', () => {
    if(window.Openscop.url_get_alert_bloc) window.Openscop.refreshAlerts()
})


window.Openscop.refreshAlerts = () => {
    $get(window.Openscop.url_get_alert_bloc).then((response) => {
        const parent = $<HTMLElement>('#openscop-alertes')
        parent!.innerHTML = response.body
        $$('[data-trigger="tab"]', parent!).forEach(el => {
            closeActionsMenu()
            el.addEventListener('click', switchTab)
        })
        $$('.confirmer_cdsr').forEach(el => el.addEventListener('click', showModalConfirmationCdsr))
    })
}