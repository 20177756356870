import {$, $$, empty, html_chargement_en_cours} from "../../core/js/Dom.ts";
import {$get} from "../../core/js/Ajax.ts";
import {fadeOutFadeIn} from "../../core/js/Animate.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url?.startsWith("stat")) {

        const stats = new Stats("tabpanel-manifestation-panel")
        $$('.fr-tabs__tab').forEach((element) => {
            element.addEventListener('click', () => {
                stats.onglet = element.getAttribute('aria-controls')!
                stats.get_panel()
            });
        });
        $$('.go_get_panel').forEach((element) => {
            element.addEventListener('change', () => {
                stats.get_panel();
            });
        });
        $('#btn_unique_btn')?.addEventListener('click', (e) => {
            e.preventDefault();
            fadeOutFadeIn($('#btn_double_div'), $('#btn_unique_div')).finally(() => {
                stats.type = "unique";
                stats.get_panel();
            })
        });
        $('#btn_double_btn')?.addEventListener('click', function (e) {
            e.preventDefault();
            fadeOutFadeIn($('#btn_unique_div'), $('#btn_double_div')).finally(() => {
                stats.type = "double";
                stats.get_panel();
            })
        });
    }
})

class Stats {
    private _onglet: string
    private _type: "unique" | "double" = "unique"

    constructor(onglet: string) {
        this._onglet = onglet

        this.get_panel()
    }


    set onglet(value: string) {
        this._onglet = value;
    }

    set type(value: "unique" | "double") {
        this._type = value;
    }

    async get_panel() {
        const ongletElement = $(`#${this._onglet}`)
        if (ongletElement) {
            empty(ongletElement)
            ongletElement.appendChild(html_chargement_en_cours("Calcul des statistiques en cours..."))
            if (this._type === "unique") {
                let instance = $<HTMLInputElement>('#dept')
                let annee = $<HTMLInputElement>('#annee')

                if (instance && annee) {
                    const instanceValue = instance.value
                    const anneeValue = annee.value

                    const url_final_instance = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instanceValue}&annee=${anneeValue}`;
                    $get(url_final_instance).then(response => {
                        empty(ongletElement)
                        ongletElement.innerHTML = response.body
                    })
                }
            } else {
                let instance_d = $<HTMLSelectElement>('#dept_d')
                let instance_s = $<HTMLSelectElement>('#dept_d_s')
                let anneeu = $<HTMLInputElement>('#anneeu')
                if (instance_d && instance_s && anneeu) {
                    const instance_dValue = instance_d.value
                    const instance_sValue = instance_s.value
                    const anneeuValue = anneeu.value
                    const url_final_instance_d = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instance_dValue}&annee_unique=${anneeuValue}`;
                    const url_final_instance_s = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instance_sValue}&annee_unique=${anneeuValue}`;

                    const {body: reponseu} = await $get(url_final_instance_d)
                    const {body: responsed} = await $get(url_final_instance_s)

                    ongletElement.innerHTML = `<div class="fr-grid-row--gutters fr-grid-row">
                        <div class="fr-col-6">
                            <h2>${instance_d.options[instance_d.selectedIndex].text}</h2>
                            ${reponseu}
                        </div>
                        <div class="fr-col-6">
                            <h2>${instance_s.options[instance_s.selectedIndex].text}</h2>
                            ${responsed}
                        </div>
                    </div>`
                }
            }
        }
    }
}