import {$, empty, getParentElementByTagName, html_chargement_en_cours, initFullscreen} from "../../core/js/Dom.ts";
import {$get, $postForm} from "../../core/js/Ajax.ts";
import {initTooltips} from "../../core/js/Tooltip.ts";
import {animateLoadingButton, fadeOutFadeIn} from "../../core/js/Animate.ts";

export function initGlobalSearch() {
    const form = $('#search-global')
    form?.addEventListener('submit', handleSearchSubmit)
}

function handleSearchSubmit(event: DOMEvent<HTMLFormElement>) {
    event.preventDefault()
    const data = new FormData(event.target)
    const modalId = 'search-global-modal'
    const modale = $(`#${modalId}`)
    const modalBody = $(`#${modalId}-modal__content`)
    const frModal = window.dsfr(modale).modal

    if (modale && modalBody) {
        empty($('#block_tableaubord_liste'))
        empty(modalBody)
        modalBody.appendChild(html_chargement_en_cours())
        frModal.disclose()
        modale?.addEventListener('dsfr.conceal', () => {
            empty(modalBody)
            modalBody.appendChild(html_chargement_en_cours())
        })
        $get(event.target.action, {recherche: data.get('recherche')}).then((response) => {
            modalBody.innerHTML = response.body
            initTooltips(modale)
            initFullscreen($('#active-fullscreen'))
            ligneToDetail()
            $('#etendre-recherche-global', modalBody)?.addEventListener('click', etendreRecherche)
        })
    }
}

function ligneToDetail() {
    const parentListeDossierInstruction = document.querySelector<HTMLElement>('.liste_dossier');
    if (parentListeDossierInstruction) {
        if (!parentListeDossierInstruction.classList.contains('event-attached')) {
            parentListeDossierInstruction.addEventListener('click', (e: Event) => {
                const target = e.target as HTMLElement;
                const clickedTr = target.closest('tr.clickligne');
                if (clickedTr && !target.classList.contains('select-checkbox')) {
                    const href = clickedTr.getAttribute('data-href');
                    const url = href ?? '';
                    window.open(url, '_blank')?.focus();
                }
            });
        }
        parentListeDossierInstruction.classList.add('event-attached');
    }
}

function etendreRecherche(event: DOMEvent<HTMLButtonElement>) {
    const target = getParentElementByTagName(event.target, 'button')
    const url = target?.dataset.url
    const recherche = target?.dataset.recherche
    if(url && recherche) {
        animateLoadingButton(target, "Recherche...")
        const form = new FormData()
        form.append('recherche', recherche)
        form.append('type_page', "modal")
        $postForm(url, form).then((response) => {
            const content = $('#etendre-recherche-content')
            if (content) {
                content.innerHTML = response.body
                fadeOutFadeIn(target, content)
            }
        })
    }
}