import {createListe} from "../../core/js/List.ts";
import {initSort} from "../../core/js/SortUtil.ts";
import {$} from "../../core/js/Dom.ts";

document.addEventListener('DOMContentLoaded', () => {
    if(document.body.dataset.url === "list_logs") {
        createListe("logs_list", {
            valueNames: ['user', 'service', 'email', 'type', {attr: 'data-timestamp', name: 'date'}]
        })
        initSort($('#logs_list'))
    }
})