import {Calendar} from '@fullcalendar/core';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {createComplexSelect} from "../../core/js/Select.ts";
import type {Option} from "slim-select/src/slim-select/store.ts";
import {$} from "../../core/js/Dom.ts";
import {fadeIn, fadeOut} from "../../core/js/Animate.ts";

document.addEventListener('DOMContentLoaded', () => {
    if(document.body.dataset.url?.indexOf('instructions_calendrier') !== -1){
        initCalendar()
    }
    if (document.body.dataset.url?.indexOf('calendar') !== -1){
        init()
    }
})


function init() {
    const params = new URLSearchParams(location.search)

    createComplexSelect({
        select: '#departement',
        events: {
            afterChange: (newVal: Option[]) => {
                updateCalendar({dep: newVal[0].value})
            }
        },
        settings: {
            placeholderText: 'Sélectionner une option',
        }
    })

    if(params.has('dep')) {
        initCalendar()
    }
}



const initCalendar = () => {
    $('#calendar-filter-btn')?.addEventListener('click', toggleDisplayFilters)
    fadeIn($('#calendar-filters'))
    let calendarEl: HTMLElement = document.getElementById('calendar')!;
    createComplexSelect({
        select: '#activite',
        events: {
            afterChange: (newVal: Option[]) => {
                updateActivite(newVal[0].value)
            }
        },
        settings: {
            placeholderText: 'Sélectionner une option',
        }
    })
    createComplexSelect({
        select: '#commune',
        events: {
            afterChange: (newVal: Option[]) => {
                updateCalendar({com: newVal[0].value})
            }
        },
        settings: {
            placeholderText: 'Sélectionner une option',
        }
    })
    const dateInput = $<HTMLInputElement>('#date')!

    let calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        // options here
        events: window.Openscop.calendrier_events,
        initialView: 'dayGridMonth',
        weekends: true,
        weekNumbers: false,
        navLinks: true,
        eventDisplay: 'block',
        views: {
            dayGrid: {
                dayMaxEventRows: 3,
                displayEventTime: false,
            },
            timeGrid: {
                slotMinTime: "06:00:00",
                slotMaxTime: "20:00:00",
                displayEventTime: true,
                timeGridEventMinHeight: 50,
            },
        },
        locale: frLocale,
        headerToolbar: {
            left: 'today prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay listMonth'
        },
        buttonText: {
            listMonth: 'Liste mensuelle',
        },
        height: 'auto',
        titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},

        eventContent: function (info) {
            const hasIconeInstruction = info.event.extendedProps.icone_instruction
            let html = `<div class="openscop-calendar-event">`
            html += `<div>${hasIconeInstruction ? hasIconeInstruction : info.event.extendedProps.icone_dossier} ${info.event._def.title}</div>`
            return {html};
        },
    });

    calendar.render();

    if(dateInput){
        dateInput.addEventListener('change', () => {
            calendar.changeView('timeGridDay', dateInput.value)
        })
    }
    // Option pour cacher les dossiers en lecture
    if($('#toggle-acces-lecture')){
        const hideAccesInput = $<HTMLInputElement>('#toggle-acces-lecture')!
        hideAccesInput.addEventListener('change', () => {
            if(hideAccesInput.checked){
                // @ts-ignore
                window.location = window.location.href.split("?")[0];
            }
            else {
                // @ts-ignore
                window.location = window.location.href.split("?")[0] + "?hide_lecture=True";
            }
        })
    }
}

const updateActivite = (activite: string|null) => {
    const params = new URLSearchParams(location.search)
    if(activite !== "" && activite) {
        location.assign(`/calendar/${activite}/?${params.toString()}`)
    } else {
        location.assign(`/calendar/?${params.toString()}`)
    }
}
const updateCalendar = (newParams: any) => {
    const params = new URLSearchParams(location.search)
    Object.keys(newParams).forEach(key => {
        if(newParams[key] === "0") {
            params.delete(key)
        } else {
            params.set(key, newParams[key])
        }
    })
    history.pushState(null, '', "?"+params.toString());
    location.reload()
}

function toggleDisplayFilters() {
    const filtres = $('#calendar-filters-values')
    if(filtres?.classList.contains('d-none')) {
        fadeIn(filtres)
    } else  {
        fadeOut(filtres)
    }
}