document.addEventListener('DOMContentLoaded', () => {
    let duration = 1000
    for (const element of document.querySelectorAll('.stat_result')) {
        if (element instanceof HTMLElement && element.dataset.start && element.dataset.end) {
            animate_number(element, parseInt(element.dataset.start), parseInt(element.dataset.end), duration)
            duration += 250
        }
    }
})


const animate_number = (obj: HTMLElement, initVal: number, lastVal: number, duration: number) => {
    let startTime: number | null = null

    //pass the current timestamp to the step function
    const step = (currentTime: number) => {
        //if the start time is null, assign the current time to startTime
        if (!startTime) {
            startTime = currentTime
        }
        //calculate the value to be used in calculating the number to be displayed
        const progress = Math.min((currentTime - startTime) / duration, 1)

        //calculate what to be displayed using the value gotten above
        obj.innerText = Intl.NumberFormat('fr').format(Math.floor(progress * (lastVal - initVal) + initVal))

        //checking to make sure the counter does not exceed the last value (lastVal)
        if (progress < 1) {
            window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    };
    //start animating
    window.requestAnimationFrame(step);
}