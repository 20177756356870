import {$, $$} from "../../core/js/Dom.ts";
import {fadeOutFadeIn} from "../../core/js/Animate.ts";
import {$postForm, $post} from "../../core/js/Ajax.ts";
import {initPrescriptionUpdateEvenementPrecedent} from "../../instructions/js/Prescription.ts";

document.addEventListener('DOMContentLoaded', () => {
    $$('.go_recherche_adresse').forEach(searchBtn => searchBtn.addEventListener("click", (event: DOMEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const target = event?.target
        appliquerFiltres(target?.getAttribute('data-list'))
    }))
    $$('.btn_geographique').forEach(geographieBtn => geographieBtn.addEventListener('click', (event: DOMEvent<HTMLButtonElement>) => {
        const target = event.target
        fadeOutFadeIn(target, $(`#${target.getAttribute('data')}`))
    }))
})

// Fonction pour appliquer les filtres
export async function appliquerFiltres(liste_titre: string) {
    let html = `<div class="blocs-chargement-spinner fr-alert fr-alert--info"> Chargement en cours<i class="remix-spinner lg" aria-hidden="true"></i></div>`

    const liste = $(`#liste_${liste_titre}`)
    if (liste) {
        liste.innerHTML = html
        // Récupérer les valeurs des champs de recherche et de date
        const form = $<HTMLFormElement>(`#filtre_recherche_avance_${liste_titre}`)
        if (form) {
            const formData = new FormData(form)
            $postForm(form?.action, formData).then(response => {

                let searchField = document.getElementById(`recherche_text_${liste_titre}`) as HTMLInputElement;
                // Si on recherche un text
                if (searchField && searchField.value) {
                    // Parcourir les URL et effectuer la requête AJAX POST
                    const urls = {
                        numero_dossier: "Numéro de dossier",
                        nom_dossier: "Nom du dossier",
                        nom_declarant: "Nom du déclarant",
                        email_declarant: "Adresse e-mail du déclarant",
                        nom_structure: "Nom de la structure organisatrice",
                        lieu_dossier: "Lieu (Uniquement pour les manifestations non revendicatives)",
                    };
                    liste.innerHTML = ""
                    Object.entries(urls).forEach(([url, titre]) => {
                        var divResult = document.createElement('div');
                        divResult.classList.add(
                            `${liste_titre}_${url}_resulte`,
                            'fr-col-12',
                        );
                        divResult.innerHTML = `<h5 class="fr-my-2w">${titre} :</h5>${html}`
                        liste.appendChild(divResult)
                        $post(form?.action + url + '/', {
                            'list_pk': response.body,
                            'recherche_text': searchField.value
                        }).then(response => {
                            $(`.${liste_titre}_${url}_resulte .blocs-chargement-spinner`)?.remove()
                            var divListResult = document.createElement('div');
                            divListResult.innerHTML = response.body
                            divResult.appendChild(divListResult)
                            if (liste_titre !== "recherche_avancee") {
                                initPrescriptionUpdateEvenementPrecedent()
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                    });
                }
                else {
                    liste.innerHTML = response.body
                }
                if (liste_titre !== "recherche_avancee") {
                    initPrescriptionUpdateEvenementPrecedent()
                }
            })
        }
    }
}