import {createTooltip} from "../../core/js/Tooltip.ts";
import {$$, getParentElementByTagName} from "../../core/js/Dom.ts";

(function () {
    enum speedLabel {
        'ok' = 'Connexion réseau ok',
        'slow' = 'Connexion réseau lente',
        'offline' = 'Connexion réseau indisponible',
    }

    /**
     * Utilise l'api navigator.connection pour avoir une estimation du débit de la connection du client
     * @returns {Promise<"slow"|"ok"|"offline">} retourne la vitesse de la connection détecté
     */
    function getNetworkSpeedWithConnectionApi(): Promise<"slow" | "ok" | "offline"> {
        return new Promise((resolve) => {
            // @ts-ignore
            const connection = navigator.connection
            let speed = connection.effectiveType
            if (connection.rtt === 0 && connection.downlink === 0) {
                speed = 'offline'
            } else if (["slow-2g", "2g", "3g"].includes(speed)) {
                speed = "slow"
            } else {
                speed = 'ok'
            }
            resolve(speed)
        })
    }

    /**
     * calcule la vitesse de téléchargement en fonction du temps et de la taille
     * @param {number} start le nombre de millisecondes au début du chargement de la requête
     * @param {number} end le nombre de millisecondes a la fin du chargement de la requête
     * @param {number} downloadSize la taille totale de la requête
     * @returns {"slow"|"ok"} l'identifiant de vitesse de la connection
     */
    function calculateSpeed(start: number, end: number, downloadSize: number): string {
        const timeDuration = (end - start) / 1000;
        const loadedBits = downloadSize * 8;
        /* Converts a number into string
               using toFixed(2) rounding to 2 */
        const bps = Number((loadedBits / timeDuration).toFixed(2));
        const speedInKbps = Number((bps / 1024).toFixed(2));
        const speedInMbps = Number((speedInKbps / 1024).toFixed(2));
        let speed = "ok"
        if (speedInMbps < 2) {
            speed = "slow"
        }
        return speed
    }

    /**
     * Pour les navigateurs qui n'ont pas l'api navigator.connection,
     * on utilise une view django qui retourne du json de taille fixe pour calculer la vitesse de la connection
     * on utilise XMLHttpRequest car fetch donne des résultats obsolète sous firefox
     * de plus il faut prévoir un temps entre chaque requête suffisant pour éviter
     * que deux requêtes soient exécutées en parallel, cela foire les résultats sous firefox (addition du temps de chaque requête...)
     * @param {HTMLElement} element l'élément HTML qui contient l'URL à appeler
     * @returns {Promise<"slow"|"ok"|"offline">} retourne la vitesse de la connection détecté
     */
    function getNetworkSpeedWithoutConnectionApi(element: HTMLElement) {
        return new Promise((resolve) => {
            const linkToCall = element.getAttribute('data-check');

            const time_start = performance.now();

            let xhr = new XMLHttpRequest();
            xhr.open('GET', linkToCall + '?' + performance.now())

            const onLoad = () => {
                const size = Number(xhr.getResponseHeader('content-length'))
                const end_time = performance.now();
                resolve(calculateSpeed(time_start, end_time, size));
            }
            const onError = () => {
                resolve('offline')
            }

            xhr.onload = onLoad
            xhr.onerror = onError

            xhr.send()
        })
    }

    /**
     * fonction utilisée pour afficher ou non l'icône de problème de connection selon la vitesse de connection détecté
     * @param {HTMLElement} element l'élément qui sert à afficher l'icône
     * @param {HTMLElement} parentElement le parent de l'élément icône, utiliser pour le tooltip
     * @param {Function} getConnectionSpeed la function à utiliser pour détecter la vitesse de connection
     * @returns {Promise<void>}
     */
    async function check_internet_speed(element: HTMLElement, parentElement: HTMLElement, getConnectionSpeed: Function) {
        const speed: "slow" | "ok" | "offline" = await getConnectionSpeed(element)
        const currentSpeed = element.getAttribute('data-speed')
        if (currentSpeed === speed) {
            return
        }
        element.setAttribute('data-speed', speed)
        parentElement.classList.remove('d-none')
        if (speed === 'offline') {
        } else if (speed === 'slow') {
        } else {
            parentElement.classList.add('d-none')
        }
        parentElement.setAttribute('title', speedLabel[speed])
        createTooltip([parentElement])
    }

    /**
     * initialisation du check de vitesse de connection
     */
    document.addEventListener('DOMContentLoaded', function () {
        const elements = $$('[data-id="network-status"]')
        elements.forEach((element) => {
            const parentElement = getParentElementByTagName(element, 'li')
            if (parentElement) {
                parentElement.classList.add('d-none')
                // @ts-ignore
                const getConnectionSpeed = window.navigator?.connection ? getNetworkSpeedWithConnectionApi : getNetworkSpeedWithoutConnectionApi
                // @ts-ignore
                if (window.navigator?.connection) {
                    check_internet_speed(element, parentElement, getConnectionSpeed)
                    setInterval(async function () {
                        check_internet_speed(element, parentElement, getConnectionSpeed)
                    }, 10000);
                } else {
                    setTimeout(() => {
                        check_internet_speed(element, parentElement, getConnectionSpeed)
                        setTimeout(async function () {
                            check_internet_speed(element, parentElement, getConnectionSpeed)
                        }, 15000);
                    }, 15000)
                }
            }
        })
    })
})();


