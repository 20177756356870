import {$, show} from "../../core/js/Dom.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url === 'parametre_notification_messagerie') {
        const pushactif = window.Openscop.pushactif
        const endpoints = window.Openscop.push_endpoints
        const serviceWorker = $<HTMLMetaElement>('meta[name="service-worker-js"]')!.content;
        navigator.serviceWorker.register(serviceWorker)
            .then((reg) => {
                    const registration = reg;
                    let navpoint;
                    registration.pushManager.getSubscription().then((nav) => {
                        let ok = 0;
                        if (nav) {
                            navpoint = nav.endpoint;
                            if (pushactif === 1) {
                                for (let i = 0; i < endpoints.point.length; i++) {
                                    if (endpoints.point[i] === navpoint) {
                                        ok = 1;
                                    }
                                }
                            }
                        }
                        if (ok === 1) {
                            const subBtn = $<HTMLButtonElement>('#webpush-subscribe-button');
                            subBtn!.innerText = 'Se désabonner des notifications Push';
                            subBtn!.disabled = false;
                            window.isPushEnabled = true;
                        } else {
                            show($<HTMLElement>('#alert_notif_push'))
                        }
                    })
                }
            );
    }
})