// function qui va créer le compteur et l'affiché.
import {$get} from "../../core/js/Ajax.ts";
import {$, $$} from "../../core/js/Dom.ts";
import {initTooltips} from "../../core/js/Tooltip.ts";

function CPT() {
    if (window.Openscop.is_log) {
        let salt = Math.round(Math.random() * 1000000)
        const url = `${window.Openscop.url_cpt}${salt}/`
        $get(url).then((data) => {
            $$('.cpt_msg_nav').forEach(element => {
                element.innerHTML = data.body
                initTooltips(element)
                let total = 0
                $$('.fr-badge', element).forEach((el) => {
                    total += Number(el.dataset.nombre)
                })
                const showTotalElement = $<HTMLElement>('#cpt_total')
                if (showTotalElement) showTotalElement.innerText = "" + total
            });
            // si django nous renvoi une erreur (comme un 403 ca rpàas co) on arrete le rechargement du compteur
        }).catch(() => {
            window.Openscop.is_log = "False"
        })

    }
}

document.addEventListener('DOMContentLoaded', () => {
    CPT();
    setInterval(function () {
        CPT()
    }, window.Openscop.MESSAGERIE_CPT_TIME_REFRESH * 1000 * 60);
})